import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import {
  HubHeader,
  Footer,
  Intro,
  EmbracingJoy,
  // EventCarousel,
  RenderWhenReady,
  SEO,
  FeelTheJoy,
  AgendaAllDaysVisibleVersion
} from 'components';
import { LocalContext, FirebaseContext } from 'context';
import { useWindowSize } from 'hooks';
import { scrollToAnchor } from 'utils';
import { useLocation } from '@reach/router';
import { pfizer } from 'styles';

const introText = `
  <h2>Our Theme</h2>
  <br />
  <p>
  Oncology is an important priority for Emerging Markets. In many EM countries, cancer incidence is on the rise with estimated over 15 million new cases of cancer expected by 2030 which is more than half of approximate 26 million new cases expected across the world.
  <br /><br />
  Since a vast majority of cancer patients come from Emerging Markets, we have <strong>a responsibility to understand their needs and help them with our innovative portfolio.</strong>
  <br /><br />
  This year we are <strong>Leaping Into The Future!</strong> Building off our tremendous momentum in 2022, we are now taking our <strong>Bold Move 3.3</strong> and putting them into action. With an estimated 180 new product launches in oncology over the next three years across emerging markets, we are uniquely primed to impact a significant number of patients with our innovative therapies as we work towards bringing <strong>Breakthroughs to Billions</strong>.
  <br /><br />
  Together, let's <strong>leap into the future</strong> as we <strong>continue to transform</strong> the way we engage our patients and physicians.
  </p>
`;

const Home = () => {
  const { setSelectedEvent } = useContext(LocalContext);
  const { user } = useContext(FirebaseContext);
  const { windowWidth } = useWindowSize();

  useEffect(() => {
    setSelectedEvent(null);
  }, []);

  // useEffect(() => {
  //   if (user) {
  //     scrollToAnchor('info', {
  //       duration: 1200,
  //       offset: -20
  //     });
  //   }
  // }, [user]);

  return (
    <>
      <SEO pageSpecificTitle="Home" />
      <HubHeader colors={pfizer} />
      {user && <Intro text={introText} colors={pfizer} />}
      {user && <EmbracingJoy />}
      {/* {user && <EventCarousel />} */}
      {/* {user && (
        <Note id="agenda">
          <h3>Note</h3>
          <p>
            Watch this space as we release more information closer to the conference launch on Jan
            24th.{windowWidth < 750 ? <>&nbsp;</> : <br />}In the meantime browse the agenda below
            and click the + icons to save the date to your local calendars.
          </p>
        </Note>
      )} */}
      {user && (
        <RenderWhenReady>
          <AgendaAllDaysVisibleVersion />
        </RenderWhenReady>
      )}
      <Footer />
    </>
  );
};

const Note = styled.section`
  background-color: rgba(196, 196, 196, 0.2);
  margin: 0 auto;
  max-width: 1164px;
  padding: 29px 29px 34px;
  text-align: center;

  h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5em;
  }

  h3,
  p {
    color: #3c3c3c;
  }
`;

export default Home;
